//png
import capsicum_png from '../assets/crops/pngs/capsicum.png';
import cauliflower_png from '../assets/crops/pngs/cauliflower.png';
import chilli_png from '../assets/crops/pngs/chilli.png';
import coffee_png from '../assets/crops/pngs/coffee.png';
import cucumber_png from '../assets/crops/pngs/cucumber.png';
import eggplant_png from '../assets/crops/pngs/eggplant.png';
import grapes_png from '../assets/crops/pngs/grapes.png';
import guava_png from '../assets/crops/pngs/guava.png';
import mango_png from '../assets/crops/pngs/mango.png';
import orange_png from '../assets/crops/pngs/orange.png';
import papaya_png from '../assets/crops/pngs/papaya.png';
import pomegranate_png from '../assets/crops/pngs/pomegranate.png';
import potato_png from '../assets/crops/pngs/potato.png';
import rice_png from '../assets/crops/pngs/rice.png';
import sugarcane_png from '../assets/crops/pngs/sugercane.png';
import tea_png from '../assets/crops/pngs/tea.png';
import tomato_png from '../assets/crops/pngs/tomato.png';

//commons
import Weather_screen from '@/assets/crop_images/commons/weather.png';
import degree from '@/assets/crop_images/commons/degree.png';

//homeScreen
import Capsicum_home_screen from '@/assets/crop_images/home_screens/Capsicum.png';
import Cauliflower_home_screen from '@/assets/crop_images/home_screens/Cauliflower.png';
import Chilli_home_screen from '@/assets/crop_images/home_screens/Chilli.png';
import Coffee_home_screen from '@/assets/crop_images/home_screens/Coffee.png';
import Cucumber_home_screen from '@/assets/crop_images/home_screens/Cucumber.png';
import Eggplant_home_screen from '@/assets/crop_images/home_screens/Eggplant.png';
import Grapes_home_screen from '@/assets/crop_images/home_screens/Grapes.png';
import Guava_home_screen from '@/assets/crop_images/home_screens/Guava.png';
import Mango_home_screen from '@/assets/crop_images/home_screens/Mango.png';
import Orange_home_screen from '@/assets/crop_images/home_screens/Orange.png';
import Papaya_home_screen from '@/assets/crop_images/home_screens/Papaya.png';
import Pomo_home_screen from '@/assets/crop_images/home_screens/Pomo.png';
import Potato_home_screen from '@/assets/crop_images/home_screens/Potato.png';
import Sugarcane_home_screen from '@/assets/crop_images/home_screens/Sugarcane.png';
import Tea_home_screen from '@/assets/crop_images/home_screens/Tea.png';
import Tomato_home_screen from '@/assets/crop_images/home_screens/Tomato.png';
import Wheat_home_screen from '@/assets/crop_images/home_screens/Wheat.png';

//irrigation screens
import Capsicum_irrigation_screen from '@/assets/crop_images/irrigation_screens/Capsicum.png';
import Cauliflower_irrigation_screen from '@/assets/crop_images/irrigation_screens/Cauliflower.png';
import Chilli_irrigation_screen from '@/assets/crop_images/irrigation_screens/Chilli.png';
import Coffee_irrigation_screen from '@/assets/crop_images/irrigation_screens/Coffee.png';
import Cucumber_irrigation_screen from '@/assets/crop_images/irrigation_screens/Cucumber.png';
import Eggplant_irrigation_screen from '@/assets/crop_images/irrigation_screens/Eggplant.png';
import Grapes_irrigation_screen from '@/assets/crop_images/irrigation_screens/Grapes.png';
import Guava_irrigation_screen from '@/assets/crop_images/irrigation_screens/Guava.png';
import Mango_irrigation_screen from '@/assets/crop_images/irrigation_screens/Mango.png';
import Orange_irrigation_screen from '@/assets/crop_images/irrigation_screens/Orange.png';
import Papaya_irrigation_screen from '@/assets/crop_images/irrigation_screens/Papaya.png';
import Pomo_irrigation_screen from '@/assets/crop_images/irrigation_screens/Pomo.png';
import Potato_irrigation_screen from '@/assets/crop_images/irrigation_screens/Potato.png';
import Sugarcane_irrigation_screen from '@/assets/crop_images/irrigation_screens/Sugarcane.png';
import Tea_irrigation_screen from '@/assets/crop_images/irrigation_screens/Tea.png';
import Tomato_irrigation_screen from '@/assets/crop_images/irrigation_screens/Tomato.png';
import Wheat_irrigation_screen from '@/assets/crop_images/irrigation_screens/Wheat.png';

// //disease
import Capsicum_disease_screen from '@/assets/crop_images/crops/Capsicum/disease.png';
import Cauliflower_disease_screen from '@/assets/crop_images/crops/Cauliflower/disease.png';
import Chilli_disease_screen from '@/assets/crop_images/crops/Chilli/disease.png';
import Coffee_disease_screen from '@/assets/crop_images/crops/Coffee/disease.png';
import Cucumber_disease_screen from '@/assets/crop_images/crops/Cucumber/disease.png';
import Eggplant_disease_screen from '@/assets/crop_images/crops/Eggplant/disease.png';
import Grapes_disease_screen from '@/assets/crop_images/crops/Grapes/disease.png';
import Guava_disease_screen from '@/assets/crop_images/crops/Guava/disease.png';
import Mango_disease_screen from '@/assets/crop_images/crops/Mango/disease.png';
import Orange_disease_screen from '@/assets/crop_images/crops/Orange/disease.png';
import Papaya_disease_screen from '@/assets/crop_images/crops/Papaya/disease.png';
import Pomo_disease_screen from '@/assets/crop_images/crops/Pomo/disease.png';
import Potato_disease_screen from '@/assets/crop_images/crops/Potato/disease.png';
import Sugarcane_disease_screen from '@/assets/crop_images/crops/Sugarcane/disease.png';
import Tea_disease_screen from '@/assets/crop_images/crops/Tea/disease.png';
import Tomato_disease_screen from '@/assets/crop_images/crops/Tomato/disease.png';
import Wheat_disease_screen from '@/assets/crop_images/crops/Wheat/disease.png';

// //fertigation
import Capsicum_fertigation_screen from '@/assets/crop_images/crops/Capsicum/fertigation.png';
import Cauliflower_fertigation_screen from '@/assets/crop_images/crops/Cauliflower/fertigation.png';
import Chilli_fertigation_screen from '@/assets/crop_images/crops/Chilli/fertigation.png';
import Coffee_fertigation_screen from '@/assets/crop_images/crops/Coffee/fertigation.png';
import Cucumber_fertigation_screen from '@/assets/crop_images/crops/Cucumber/fertigation.png';
import Eggplant_fertigation_screen from '@/assets/crop_images/crops/Eggplant/fertigation.png';
import Grapes_fertigation_screen from '@/assets/crop_images/crops/Grapes/fertigation.png';
import Guava_fertigation_screen from '@/assets/crop_images/crops/Guava/fertigation.png';
import Mango_fertigation_screen from '@/assets/crop_images/crops/Mango/fertigation.png';
import Orange_fertigation_screen from '@/assets/crop_images/crops/Orange/fertigation.png';
import Papaya_fertigation_screen from '@/assets/crop_images/crops/Papaya/fertigation.png';
import Pomo_fertigation_screen from '@/assets/crop_images/crops/Pomo/fertigation.png';
import Potato_fertigation_screen from '@/assets/crop_images/crops/Potato/fertigation.png';
import Sugarcane_fertigation_screen from '@/assets/crop_images/crops/Sugarcane/fertigation.png';
import Tea_fertigation_screen from '@/assets/crop_images/crops/Tea/fertigation.png';
import Tomato_fertigation_screen from '@/assets/crop_images/crops/Tomato/fertigation.png';
import Wheat_fertigation_screen from '@/assets/crop_images/crops/Wheat/fertigation.png';

export const cropArray = [
  {
    text: 'capsicum',
    title: 'Capsicum',
    description:
      'Capsicum are warm-season crops that grow best in warm climates and can be grown in a variety of soils, as long as they are well-drained and fertile. Capsicum or pepper come in various color, shapes and sizes. From transplantation to harvest Fyllo helps you grow the best quality capsicum.',
    subHeading: 'Solutions for the Capsicum cultivation are:',
    images: {
      0: Capsicum_home_screen,
      1: Capsicum_irrigation_screen,
      2: Capsicum_disease_screen,
      3: Capsicum_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on your mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Too much water or too less water can be very harmful for capsicum. Over irrigation leads to wilting in the plants. Capsicum’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in capsicum are Wilt, Bacterial Spot, Cercospora and Powdery Mildew. Pests that can harm the crop include Thrips, White flies, Mites, Aphids and Fruit Borer. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.
            
            The disease and pest prediction models are based on stage of the crop and favorable climate.
            
            You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Capsicum crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Capsicum crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Capsicum crop badly. Also, leaf wetness and high humidity lead to fungal diseases. Bearing of flowers also depends temperature. Fyllo’s farm

            level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },
      {
        title: 'Specific solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect fruit size, quality, color or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: capsicum_png,
    },
  },
  {
    text: 'cauliflower',
    title: 'Cauliflower',
    description:
      'Cauliflower and Cabbage belonging to Crucifers family are popular vegetable crops that are widely cultivated in many parts of the world, including Asia, Africa, and the Mediterranean region. From sowing to harvest Fyllo helps you grow the best quality Crucifers.',
    subHeading: 'Solutions for the Crucifers cultivation are:',
    images: {
      0: Cauliflower_home_screen,
      1: Cauliflower_irrigation_screen,
      2: Cauliflower_disease_screen,
      3: Cauliflower_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Too much water or too less water can be very harmful for Crucifers. Over irrigation leads to wilting in the plants. Under irrigation leads to poor fruits and yield. Crucifers’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major diseases in Crucifers are Downy Mildew, Black Rot and Alternaria. Pests that can harm the crop include Aphids, Flea Beetles and Moths. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Crucifers crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Crucifers crop experts and technologists. Crop yield target is also a factor in the nutrient schedule`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Crucifers crop badly. Flowering also needs optimal temperature to induce. Also, leaf wetness and high humidity lead to fungal diseases. Bearing

                of flowers also depends temperature. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions`,
      },
      {
        title: 'Harvest time suggestion',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: 'The yield and quality prediction model will give you insights into upcoming harvest from your farms',
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect fruit size, quality, color or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: cauliflower_png,
    },
  },
  {
    text: 'chilli',
    title: 'Chilli',
    description:
      'Chillies are warm-season crops that grow best in warm climates and can be grown in a variety of soils, as long as they are well-drained and fertile. Chilli are grown for green consumption as well as red chillies for spices. From transplantation to harvest Fyllo helps you grow the best quality chillies.',
    subHeading: 'Solutions for the Chilli cultivation are:',
    images: {
      0: Chilli_home_screen,
      1: Chilli_irrigation_screen,
      2: Chilli_disease_screen,
      3: Chilli_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Too much water or too less water can be very harmful for chilli. Over irrigation leads to wilting in the plants. Chilli’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in chilli are Wilt, Powdery Mildew and Anthracnose. Pests that can harm the crop include Thrips, White flies, mites and midge. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule. The disease and pest prediction models are based on stage of the crop and favorable climate.You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Chilli crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Chilli crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Chilli crop badly. Also, leaf wetness and high humidity lead to fungal diseases. Bearing of flowers also depends temperature. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: 'The yield and quality prediction model will give you insights into upcoming harvest from your farms',
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect fruit size, quality, color or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: chilli_png,
    },
  },
  {
    text: 'coffee',
    title: 'Coffee',
    description:
      'Coffee is a popular beverage and is widely grown in many countries around the world, particularly in regions with a suitable climate. Coffee grows best in regions with a warm and humid climate, high rainfall, and well-drained soils. From plantation to harvest Fyllo helps you grow the best quality Coffee varieties.',
    subHeading: 'Solutions for the Coffee cultivation are:',
    images: {
      0: Coffee_home_screen,
      1: Coffee_irrigation_screen,
      2: Coffee_disease_screen,
      3: Coffee_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Garden monitoring using device:',
        content: `Fyllo device installed at your garden monitors your garden 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Precise irrigation given to the Coffee can help you get the best quality beans from the plants. Over irrigation leads to wilting in the plants. Coffee’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in Coffee is Cercospora leaf spot, Bacterial Blight and coffee berry disease. Pests that can harm the crop include Black twig borer and Coffee Berry Borer. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Coffee crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Coffee crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Monitoring climate and rainfall is very important to plan the harvest cycles in Coffee. Also, leaf wetness and high humidity lead to fungal diseases. Fyllo’s garden level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance. You can set and plan the harvest cycles easily with Fyllo in your Coffee gardens.`,
      },
      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your gardens.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop target',
        content: `Each garden may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect quality or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: coffee_png,
    },
  },
  {
    text: 'cucumber',
    title: 'Cucumber',
    description:
      'Be it cucumber, melons or gherkins, cucurbit family is an important and versatile crop, grown for both fresh consumption and processing into products such as pickles and juices. From sowing to harvest Fyllo helps you grow the best quality Cucurbits. With Fyllo’s solutions, you can grow spotless, juicy and sweet Cucurbits.',
    subHeading: 'Solutions for the Cucurbits cultivation are:',
    images: {
      0: Cucumber_home_screen,
      1: Cucumber_irrigation_screen,
      2: Cucumber_disease_screen,
      3: Cucumber_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Irrigation is one of the important practices in cucurbit growing. Under irrigation can hamper the fruit size and quality. Over irrigation leads to wilting in the plants and more watery fruits. Cucurbits’ water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in Cucurbits are Downy Mildew, Powdery Mildew and Leaf Blight and Anthracnose. Pests that can harm the crop include Aphids, White flies and Beetles. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Cucurbits crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage- wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Cucurbits crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Cucurbits crop badly. Also, leaf wetness and high humidity lead to fungal diseases. Bearing of flowers also depends temperature. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect fruit size, color or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: cucumber_png,
    },
  },
  {
    text: 'eggplant',
    title: 'Brinjal',
    description:
      'Brinjal, also known as eggplant, is a popular vegetable crop that is widely cultivated in many parts of the world, including Asia, Africa, and the Mediterranean region. From transplantation to harvest Fyllo helps you grow the best quality Brinjals.',
    subHeading: 'Solutions for the Brinjal cultivation are:',
    images: {
      0: Eggplant_home_screen,
      1: Eggplant_irrigation_screen,
      2: Eggplant_disease_screen,
      3: Eggplant_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Too much water or too less water can be very harmful for Brinjal. Over irrigation leads to wilting in the plants. Brinjal’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in Brinjal are Bacterial Blight, Leaf Blight and Fruit Spot. Pests that can harm the crop include Aphids, Fruit Borer and Thrips. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Brinjal crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Brinjal crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },

      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Brinjal crop badly. Flowering also needs optimal temperature to induce. Also, leaf wetness and high humidity lead to fungal diseases. Bearing

                of flowers also depends temperature. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },

      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },

      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },

      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect fruit size, color or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: eggplant_png,
    },
  },
  {
    text: 'grapes',
    title: 'Grapes',
    description:
      'Vineyards require special attention in growing season. Getting the best quality and yield can be challenging if not carefully managed. With Fyllo at your vineyard, you can manage the crop from pre-pruning to harvest in the best possible way. Fyllo has a tailer made solution specially for different varities of grapevine.',
    subHeading: 'Solutions for the grape are:',
    images: {
      0: Grapes_home_screen,
      1: Grapes_irrigation_screen,
      2: Grapes_disease_screen,
      3: Grapes_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Vineyard monitoring using device:',
        content: `Fyllo device installed at your grape orchard monitors your farm 24*7 and captures 12 critical parameters in real time`,
      },
      {
        title: 'Irrigation management',
        content: `Grape’s water requirements vary based on soil type and stage. Table grape, vine grapes and raisin varieties all have different water requirements. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop .`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Grape as a crop is susceptible to various diseases and pests. Major diseases are Downy Mildew, Powdery Mildew, Anthracnose and Botrytis. Pests that can harm the crop include Thrips, Red Spider Mites, Flea beetles , Mealybug, Jassids and Caterpillars. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease/pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Grape crop need fertilizers in form of soil application, fertigation and foliar application. Our dynamic fertilizer schedule takes into account variety, rootstock, stage-wise crop needs and nutrient availability in soil and in petiole. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of viticulture experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Grape growing is very sensitive to climate. Rainfall in sensitive stage may lead to heavy crop losses. Also, leaf wetness and high humidity cause fungal diseases. With Fyllo’s farm level weather monitoring and predictions for next 2 weeks will hep you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `Fyllo’s yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal in berry size, shape, brix or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: grapes_png,
    },
  },
  {
    text: 'guava',
    title: 'Guava',
    description:
      'Guava is a tropical fruit crop that is widely grown for its sweet and juicy fruit. Many guava varieties including red and white color are popularly cultivated. Be it high density or traditional orchards, guava growing can be an uphill task without proper technology. From pruning to harvest Fyllo helps you grow the best quality fruits. With Fyllo solutions, you can grow spotless, juicy and sweet fruits.',
    subHeading: 'Solutions for the Guava cultivation are:',
    images: {
      0: Guava_home_screen,
      1: Guava_irrigation_screen,
      2: Guava_disease_screen,
      3: Guava_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Orchard monitoring using device:',
        content: `Fyllo device installed at your orchard monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity`,
      },
      {
        title: 'Irrigation management',
        content: `Guava’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major diseases in guava are Anthracnose, Styler End Rot and Fruit Canker. Pests that can harm the crop include Thrips, Tea Mosquitto Bug, Mites, Mealybug and Fruit Fly. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease/pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Guava crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Guava crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Very low temperatures are not tolerated by the Guava Crop. High times need more frequent watering. Also, leaf wetness and high humidity lead to fungal diseases. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect fruit size, color, TSS brix or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: guava_png,
    },
  },
  {
    text: 'mango',
    title: 'Mango',
    description:
      'Mangoes are tropical fruit and are grown in warm, humid climates around the world. The yellow fruit comes has many varieties that have different size, color and flavour. From transplantation to harvest Fyllo helps you grow the best quality fruits. Be it high density or normal plantation with Fyllo’s solutions, you can grow spotless, juicy and sweet mangoes.',
    subHeading: 'Solutions for the Mango cultivation are:',
    images: {
      0: Mango_home_screen,
      1: Mango_irrigation_screen,
      2: Mango_disease_screen,
      3: Mango_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Too much water or too less water can be very harmful for mango. Mango’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in mango are Powdery mildew and Anthracnose. Pests that can harm the crop include Mango hopper. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Mango crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Mango crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Mango crop badly. Also, leaf wetness and high humidity lead to fungal diseases. Bearing of flowers also depends temperature. Fyllo’s farm
                level weather monitoring and predictions for next 2 weeks will help you plan for the next actions`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your farms`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect fruit size, color, TSS brix or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: mango_png,
    },
  },
  {
    text: 'Kinnow',
    title: 'Kinnow',
    description:
      'Citrus fruits are a group of popular and nutritious fruits that are grown all over the world. Some of the most common citrus fruits include oranges, lemons, limes, grapefruits, mandarins and tangerines. With Fyllo solutions, you can grow spotless, juicy and sweet and tangy Citrus.',
    subHeading: 'Solutions for the Citrus cultivation are:',
    images: {
      0: Orange_home_screen,
      1: Orange_irrigation_screen,
      2: Orange_disease_screen,
      3: Orange_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Orchard monitoring using device:',
        content: `Fyllo device installed at your orchard monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Citrus’s water requirements vary based on soil type and stage. Water requirement also change from one variety to other and also your flowering time or season. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to beat the heat stress and maintain perfect acid/TSS ratio`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Citrus as a crop is susceptible to various diseases and pests. Major diseases are Alternaria Fruit Blotch, Bacterial Canker and Citrus Scab. Pests that can harm the crop include Thrips, Aphids, Mites, Citrus Psylla and White Fly. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease/pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Citrus crop need fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Citrus crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Continuous dry spells can also impact fruit development negatively in the citrus. Fruit drop is one major challenge happening due to climate. Also, leaf wetness and high humidity lead to

                fungal diseases. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `Fyllo’s yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal in fruit size, color, ACID/TSS ration or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: orange_png,
    },
  },
  {
    text: 'papaya',
    title: 'Papaya',
    description:
      'Papaya is a tropical fruit tree that grows well in warm, humid climates and can be a highly productive crop . From transplantation to harvest Fyllo helps you grow the best quality, colored and sweet Papayas.',
    subHeading: 'Solutions for the Papaya cultivation are:',
    images: {
      0: Papaya_home_screen,
      1: Papaya_irrigation_screen,
      2: Papaya_disease_screen,
      3: Papaya_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Too much water or too less water can be very harmful for Papaya. Over irrigation leads to wilting in the plants. Under irrigation can hamper fruit size and sweetness. Papaya’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in Papaya are Bacterial Wilt and Powdery Mildew. Pests that can harm the crop include Fruit Fly and Mealybug. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Papaya crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Papaya crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Papaya crop badly. Fruits also needs optimal temperature to induce. Also, leaf wetness and high humidity lead to fungal diseases. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect fruit size, sweetness, color or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: papaya_png,
    },
  },
  {
    text: 'pomegranate',
    title: 'Pomegranate',
    description:
      'Pomegranate growing is a wonderful and rewarding experience for those who enjoy cultivating fruit trees. The pomegranate is a hardy and long-lived tree that is well-suited for warm climates, although it can also be grown in cooler regions with the proper care. With Fyllo solutions, you can grow spotless, juicy and sweet pomegranate.',
    subHeading: 'Solutions for the pomegranate cultivation are:',
    images: {
      0: Pomo_home_screen,
      1: Pomo_irrigation_screen,
      2: Pomo_disease_screen,
      3: Pomo_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Orchard monitoring using device:',
        content: `Fyllo device installed at your orchard monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time.`,
      },
      {
        title: 'Irrigation management',
        content: `Pomegranate’s water requirements vary based on soil type and stage. Water requirement also change from one variety to other and also your defoliation time. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the stress to be given to the plants before defoliation and flowering with the help of Fyllo.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Pomegranate as a crop is susceptible to various diseases and pests. Major diseases are Bacterial Blight, Anthracnose and Cercospora. Pests that can harm the crop include Thrips, Aphids, Fruit Borer, Mealybug and Red Spider Mites. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease/pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise-
                
                progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Pomegranate crop need fertilizers in form of soil application, fertigation and foliar application. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of pomegranate crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Pomegranate growing is very sensitive to climate. Rainfall in sensitive stages lead to heavy crop losses. Continuous dry spells can also impact fruit development negatively. Also, leaf wetness and high humidity lead to fungal diseases. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `Fyllo’s yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal in fruit size, color, sweetness or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: pomegranate_png,
    },
  },
  {
    text: 'potato',
    title: 'Potato',
    description:
      'Potatoes are grown for various purposes. From consumption as vegetable or for value added products like chips, wedges, potatoes are a staple food in many countries and are widely consumed as a staple or side dish. From tuber plantation to harvest Fyllo helps you grow the best quality potato.',
    subHeading: 'Solutions for the Potato cultivation are:',
    images: {
      0: Potato_home_screen,
      1: Potato_irrigation_screen,
      2: Potato_disease_screen,
      3: Potato_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Too much water or too less water can be very harmful for potato. Over irrigation leads to wilting in the plants. Keeping soil aerated is very important for potato growing. Potato’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in potato are Early Blight and Late Blight. Pests that can harm the crop include Potato Beetles, Aphids and White Grubs. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Potato crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Potato crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Potato crop badly. Also, leaf wetness and high humidity lead to fungal diseases. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect potato size, quality or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: potato_png,
    },
  },
  {
    text: 'wheat',
    title: 'Wheat',
    description:
      'Wheat is a cereal grain that is widely cultivated and consumed around the world. It is a staple food for many people and is used to make flour, bread, pasta, and other food products. From planting to harvest, Fyllo helps you grow the best quality Wheat.',
    subHeading: 'Solutions for the Wheat cultivation are:',
    images: {
      0: Wheat_home_screen,
      1: Wheat_irrigation_screen,
      2: Wheat_disease_screen,
      3: Wheat_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Keeping soils properly irrigated is very important for wheat. Under irrigation can hamper the grain size and quality. Over irrigation leads to wilting in the plants. Wheat’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in Wheat are Brown Rust and Yellow Rust. Pests that can harm the crop include Aphids and Termites. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Wheat crop needs balanced nutrition. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Wheat crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Wheat crop badly. Also, leaf wetness and high humidity lead to fungal diseases. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect grain sizeor yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: rice_png,
    },
  },
  {
    text: 'Sugarcane',
    title: 'Sugarcane',
    description:
      "Sugarcane is a tropical, perennial crop that is grown for its sweet, juicy stalks, which are rich in sugar. It is one of the world's largest crops and is grown primarily in tropical and subtropical regions.  From planting to harvest, Fyllo helps you grow the best quality Sugarcane. With Fyllo’s solutions, you can grow spotless, juicy and sweet Sugarcane.",
    subHeading: 'Solutions for the Sugarcane cultivation are:',
    images: {
      0: Sugarcane_home_screen,
      1: Sugarcane_irrigation_screen,
      2: Sugarcane_disease_screen,
      3: Sugarcane_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Irrigation is one of the important practices in Sugarcane growing. Under irrigation can hamper the cane size and quality. Over irrigation leads to wilting in the plants and less sugar. Sugarcane’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in Sugarcane Leaf Scald, Smut and Stalk Rot. Pests that can harm the crop include Stem Borers, White Grubs, and Termites. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Sugarcane is a heavy feeder and requires large amounts of nitrogen, phosphorus, and potassium for optimal growth. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Sugarcane crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Sugarcane crop badly. Also, leaf wetness and high humidity lead to fungal diseases. Fyllo’s farm level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: 'Based on growing degree days, Fyllo helps you plan your harvest time in advance.',
      },
      {
        title: 'Yield prediction',
        content: 'The yield and quality prediction model will give you insights into upcoming harvest from your farms.',
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect cane size, sugar or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: sugarcane_png,
    },
  },
  {
    text: 'tea',
    title: 'Tea',
    description:
      'Tea is a popular beverage and is widely grown in many countries around the world, particularly in regions with a suitable climate. Tea grows best in regions with a warm and humid climate, high rainfall, and well-drained soils. From plantation to harvest Fyllo helps you grow the best quality tea varieties.',
    subHeading: 'Solutions for the Tea cultivation are:',
    images: {
      0: Tea_home_screen,
      1: Tea_irrigation_screen,
      2: Tea_disease_screen,
      3: Tea_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Garden monitoring using device:',
        content: `Fyllo device installed at your garden monitors your garden 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Precise irrigation given to the tea can help you get the best quality leaves from the bushes. Over irrigation leads to wilting in the plants. Tea’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in tea is Blister Blight. Pests that can harm the crop include Red Spider Mites and Tea Mosquito Bugs. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Tea crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Tea crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Monitoring climate and rainfall is very important to plan the picking cycles in tea. Also, leaf wetness and high humidity lead to fungal diseases. Fyllo’s garden level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance. You can set and plan the harvest cycles easily with Fyllo in your tea gardens.`,
      },
      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your gardens.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each garden may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect quality or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: tea_png,
    },
  },
  {
    text: 'tomato',
    title: 'Tomato',
    description:
      'Tomatoes are a popular and versatile crop, grown for both fresh consumption and processing into products such as sauces, soups, and juices. From transplantation to harvest Fyllo helps you grow the best quality tomatoes. With Fyllo’s solutions, you can grow spotless and juicy tomatoes.',
    subHeading: 'Solutions for the Tomato cultivation are:',
    images: {
      0: Tomato_home_screen,
      1: Tomato_irrigation_screen,
      2: Tomato_disease_screen,
      3: Tomato_fertigation_screen,
      4: Weather_screen,
      5: degree,
    },
    points: [
      {
        title: 'Farm monitoring using device:',
        content: `Fyllo device installed at your farm monitors your farm 24*7 and captures 12 critical parameters in real time. You get this data on you mobile and dashboard in real time. You get to monitor where the crop is getting enough sunlight, proper temperature or humidity.`,
      },
      {
        title: 'Irrigation management',
        content: `Too much water or too less water can be very harmful for tomato. Over irrigation leads to wilting in the plants. Tomato’s water requirements vary based on soil type and stage. With Fyllo’s device containing soil moisture and soil temperature sensor and intelligent software, you get alerts on how much water to provide to the crop. You can also see and visualize evapotranspiration (Etc) values of the crop. You can perfectly manage the water requirements to get the perfect size, color and sugar.`,
      },
      {
        title: 'Disease and Pest Management',
        content: `Major disease in tomato are Early Blight, Powdery Mildew and Late blight. Pests that can harm the crop include Aphids, White flies and caterpillar. Fyllo provides prediction of these diseases and pests along with preventive plant protection schedule.

                The disease and pest prediction models are based on stage of the crop and favorable climate.
                
                You can also monitor area wise progression of the diseases and pests. Fyllo also tells best time to spray for highest effectivity of plant protection molecules.`,
      },
      {
        title: 'Nutrient Management',
        content: `Tomato crop needs fertilizers in form of soil application, fertigation and foliar application. Some stages need more of a particular nutrient than the other. Our dynamic fertilizer schedule takes into account variety, stage-wise crop needs and nutrient availability in soil and in leaf. Fyllo nutrient schedule is generated on based of nutrient interactions in the soil and crop by a team of Tomato crop experts and technologists. Crop yield target is also a factor in the nutrient schedule.`,
      },
      {
        title: 'Climate monitoring and weather prediction',
        content: `Rainfall in critical stages can impact the Tomato crop badly. Also, leaf wetness and high humidity lead to fungal diseases. Bearing of flowers also depends temperature. Fyllo’s farm

                level weather monitoring and predictions for next 2 weeks will help you plan for the next actions.`,
      },
      {
        title: 'Harvest time suggestions',
        content: `Based on growing degree days, Fyllo helps you plan your harvest time in advance.`,
      },
      {
        title: 'Yield prediction',
        content: `The yield and quality prediction model will give you insights into upcoming harvest from your farms.`,
      },
      {
        title: 'Specific Solutions and support to reach the crop targets',
        content: `Each farm may have different goals in terms of yield, quality and market requirements. Whether your goal is getting perfect fruit size, color or yield, Fyllo system helps you achieve your crop goals.`,
      },
    ],
    image: {
      png: tomato_png,
    },
  },
];
